<template>
  <Loader
    v-if="loading"
  />
  <div
    v-else-if="error"
  >
    <h1 class="has-text-centered">
      Uh oh, an error occurred while loading this deal!
    </h1>
    <p class="has-text-danger has-text-centered">
      {{ error }}
    </p>
    <p class="has-text-centered">
      Please try again.
    </p>
  </div>
  <div
    v-else
  >
    <article
      v-if="!deal.is_active"
      class="message is-danger">
      <div class="message-header">
        <p>The deal is inactive</p>
      </div>
    </article>
    <section class="hero">
      <figure class="image is-fullwidth">
        <img :src="deal.image" />
      </figure>
    </section>
    <section
      :class="cardClass"
      style="padding: 1.5rem;"
    >
      <div class="media" style="padding: 0; margin: 0">
        <div class="media-left">
          <figure class="image is-24x24">
            <CategoryImage :name="deal.category" width="24" height="24" />
          </figure>
        </div>
        <div class="media-content">
          <p class="title has-text-white is-4">
            {{ deal.business.name }}
          </p>
        </div>
      </div>
    </section>
    <section class="container back">
      <nav class="level is-mobile">
        <div class="level-left">
          <p class="level-item">
            <button
              class="button is-outlined is-link"
              @click="back"
            >
              <span class="icon">
                <i class="fas fa-arrow-left"></i>
              </span>
              <span>Back</span>
            </button>
          </p>
        </div>
        <div
          v-if="canEdit"
          class="level-right">
          <p class="level-item">
            <DealEditMenu
              :deal="deal"
              :locationId="locationId"
            />
          </p>
        </div>
      </nav>
    </section>
    <section class="section container">
      <h2 class="subtitle">
        {{ deal.description }}
        <span 
          v-if="otherDealsCount > 0"
        >
          (<a href="#other_deals">{{ otherDealsCount }} other deals</a>)
        </span>
      </h2>
      <p class="is-italic">
        <span
          v-if="deal.is_repeatable == false"
        >
          Only one per customer.
        </span>
        <span
         v-if="deal.is_condition == true"
        >
          Cannot be combined with other offers
        </span>
      </p>
    </section>
    <section class="section container">
      <div
        class="buttons">
        <!--
        <button
          v-if="showClaimClick"
          @click="claim()"
          :disabled="disabled"
          :class="showClass()"
        >
          Claim Deal
        </button>
        -->
        <button
          v-if="showClaimQR"
          @click="scan()"
          :disabled="disabled"
          class="button is-medium is-fullwidth is-info"
        >
          {{ claimLabel }}
        </button>
        <p
          v-if="(showClaimQR || showClaimClick) && claimDescription"
          class="has-text-centered"
        >
          {{ claimDescription }}
        </p>
        <button
          v-if="!loggedIn"
          @click="go('/signup')"
          class="button is-medium is-fullwidth is-info"
        >
          Sign up or login to earn ${{ updateDollarValues(deal.award_value) }}
        </button>
        <!-- <button
          class="button is-medium is-fullwidth is-info is-outlined"
        >
          Share
        </button>  -->
      </div>
    </section>

    <section
      v-if="otherDealsCount > 0"
      class="section container content"
    >
      <nav class="panel">
        <p id="other_deals" class="panel-heading">
          Other Deals
        </p>
        <p
          v-for="d in deal.otherDeals"
          :key="d.slug"
          class="panel-block"
        >
          <a
            @click="dealLink(d.slug)"
          >
            {{ d.description }}
          </a>
        </p>
      </nav>
    </section>

    <section class="section container">
      <div class="content">
        <p><strong>About</strong></p>
        <p>
          {{ deal.business.about }}
        </p>
        <p>
          <span class="icon">
            <i class="fas fa-map"></i>
          </span>
          <a
            v-if="isPhysicalLocation"
            @click="openWebMap"
          >
            {{ formattedAddress }}
          </a>
          <span
            v-else
          >
            Serving the {{ formattedAddress }} area
          </span>
        </p>
        <p
          v-if="websiteLink != null"
        >
          <span class="icon">
            <i class="fas fa-globe"></i>
          </span>
           <a :href="websiteLink" target="_blank">
             {{ websiteLabel }}
          </a>
        </p>
        <p>
          <span class="icon">
            <i class="fas fa-phone-square"></i>
          </span>
          <a :href="phoneLink">{{ deal.business.phone }}</a>
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import {AuthError, NetworkError} from "@/errors";
import DealScanMixin from "@/mixins/DealScanMixin.js";
import Loader from "@/components/Loader.vue";
import CategoryImage from "@/components/CategoryImage.vue";
import DealEditMenu from "@/components/DealEditMenu.vue";

export default {
  props: {
    orgSlug: {
      type: String,
      required: true
    },
    slug: {
      type: String,
      required: true
    },
    locationId: {
      type: String,
      required: true
    }
  },
  mixins: [DealScanMixin],
  components: {
    Loader,
    CategoryImage,
    DealEditMenu
  },
  data() {
    return {
      loading: false,
      error: null,
      disabled: false,
      showClaimClick: false,
      showClaimQR: false,
      claimLabel: "Scan QR code",
      claimDescription: "Complete your purchase first",
      claiming: false,
      mapLink: null,
      deal: {
        description: '',
        image: 'https://res.cloudinary.com/localloot/image/upload/v1595359601/assets/LL-Placeholder.png',
        conditions: '',
        category: 'todo',
        otherDeals: [],
        currentUserStatus: null,
        currentUserTransactions: [],
        currentUserAvailableOn: "today",
        business: {
          name: '',
          image: '',
          about: '',
          phone: '',
          website: ''
        }
      },
    }
  },
  computed: {
    imgSrc: function() {
      return require('../assets/fun.png')
    },
    isAdmin: function() {
      return this.$store.getters.isAdmin;
    },
    loggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
    currentUser: function() {
      return this.$store.getters.currentUser;
    },
    currentBusiness: function() {
      return this.$store.getters.currentBusiness;
    },
    currentDeal: function() {
      return this.$store.getters.currentDeal;
    },
    canEdit: function() {
      return ((this.$store.getters.canEdit || this.isAdmin)
        && typeof this.currentDeal.slug !== 'undefined'
        && this.currentDeal.business.slug === this.currentBusiness.slug
      ) ? true : false;
    },
    currentDealLocation: function() {
      return (this.deal.business.locations) ? this.deal.business.locations.find(l => l.id == this.locationId) : null
    },
    formattedAddress: function() {
      return (this.currentDealLocation) ? this.currentDealLocation["formattedAddress"] : ""
    },
    lngLat: function() {
      return (this.currentDealLocation) ? `${this.currentDealLocation["lng"]},${this.currentDealLocation["lat"]}` : ""
    },
    websiteLink: function() {
      if (this.deal.business.website == null || this.deal.business.website == "") {
        return null
      } else if (!this.deal.business.website.match(/^https?:\/\//)) {
        return `https://${this.deal.business.website}`
      }
      return this.deal.business.website
    },
    websiteLabel: function() {
      if (this.deal.business.website == null | this.deal.business.website == "") {
        return null
      }
      const matches = this.deal.business.website.match(/^https?:\/\/(.+)$/)
      if (matches != null && matches.length > 1) {
        return matches[1]
      }
      return this.deal.business.website
    },
    phoneLink:  function() {
      return "tel:+1" + this.deal.business.phone.replace(/\s+/g, '')
    },
    isPhysicalLocation: function() {
      const locations = this.deal.business.locations
      if (!locations) {
        return null
      }
      const location = locations.find(l => l.id == this.locationId)
      return location.is_physical_location
    },
    otherDealsCount: function() {
      return (this.deal && this.deal.otherDeals) ? this.deal.otherDeals.length : 0
    },
    cardClass: function() {
      return `container ${this.deal.category} has-text-white`
    }
  },
  mounted() {
    this.updateData()
  },
  watch: {
    $route(to, from) {
      if (to.path != from.path) {
        this.updateData()
      }
    }
  },
  methods: {
    updateDollarValues(cents) {
      // convert cents to dollars
      if (cents !== 0) {
        return Math.round(cents / 100)
      } else {
        return null
      }
    },
    updateData() {
      this.loading = true
      this.$store.dispatch("getDeal", this.slug)
      .then(() => {
          this.deal = this.currentDeal;
          if (this.deal) {
            if (this.isPhysicalLocation) {
              this.setMapLink()
            }
            this.setClaimButtonStatus()
          } else {
            this.error = "Deal not found"
          }
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            this.error = error
          }
        })
        .finally(() => {
            this.loading = false
        })
    },
    claim() {
      this.disabled = true;
      this.claiming = true;
      this.showClaimClick = false
      this.showClaimQR = true
      setTimeout(() => {
        const params = {
          user_slug: this.currentUser.slug,
          deal_slug: this.deal.slug,
          location_id: parseInt(this.locationId)
        }
        this.$store.dispatch("clickClaimDeal", params)
          .then(() => {
            this.claimLabel = "Request sent";
            this.claimDescription = "It may take up to 24 hours for your claim to be processed.";
          })
          .catch(err => {
            if (err instanceof AuthError || err instanceof NetworkError) {
              throw err
            } else {
              this.error = err
            }
          })
          .finally(() => {
            this.claiming = false
          })
      }, 1000);
    },
    back() {
      this.$router.go(-1)
    },
    go(path) {
      this.$router.push(path)
    },
    dealLink(slug) {
      const path = `/deal/${this.orgSlug}/${slug}/l/${this.locationId}`
      this.$router.push(path)
      window.scrollTo(0,0);
    },
    printQr() {
      const path = `/deal/${this.deal.business.slug}/${this.deal.slug}/l/${this.locationId}/print-qr`
      this.$router.push(path)
    },
    edit() {
      const path = `/deal/${this.deal.business.slug}/${this.deal.slug}/l/${this.locationId}/edit`
      this.$router.push(path)
    },
    scan() {
      let path = `/deal/${this.deal.business.slug}/${this.deal.slug}/l/${this.locationId}/scan`
      if (window.NativeScript) {
          path += "-native";
      }
      this.$router.push(path)
    },
    showClass() {
      if (this.claiming) {
        return "button is-medium is-fullwidth is-info is-loading"
      } else {
        return "button is-medium is-fullwidth is-info"
      }
    },
    getFormattedAddress() {
      const locations = this.deal.business.locations
      const location = locations.find(l => l.id == this.locationId)
      return location["formattedAddress"]
    },
    setClaimButtonStatus() {
      if (this.loggedIn && this.deal.is_active) {
        this.showClaimClick = show_deal_claim_click(this.deal.business.award_method)
        this.showClaimQR = show_deal_claim_qr(this.deal.business.award_method)
      }

      if (!this.scanAllowed) {
        this.disabled = true
        this.claimDescription = this.reasonScanNotAllowed

        if (this.loggedIn) {
          this.showClaimQR = true
          this.showClaimClick = false
        }

        if (this.deal.currentUserStatus == "pending") {
          this.claimLabel = "Request sent"
        } else if (this.currentDealAvailableOn == "never" || this.currentDealAvailableOn == "tomorrow") {
          this.claimLabel = "Claimed"
        } else if (this.currentDealLocation.is_physical_location && !this.nearCurrentDealLocation) {
          this.claimLabel = "Claim at Location"
        }
      }
    },
    async setMapLink() {
      const base_url = "https://discoverymap.com/webmap"
      const dest = (window.NativeScript) ? this.lngLat : this.formattedAddress
      this.mapLink = `${base_url}?destination=${dest}&origin=`
      try {
        await this.$store.dispatch("getLocation")
      } catch(ex) {
        console.log(ex)
      } finally {
        const currentLocation = this.$store.getters.currentLocation
        if (currentLocation && currentLocation.lat != undefined && currentLocation.lng != undefined) {
          this.mapLink += `${currentLocation.lng},${currentLocation.lat}`
        }
      }
    },
    openWebMap() {
      if (window.NativeScript) {
        const business = {
          name: this.deal.business.name,
          address: this.formattedAddress,
          phone: this.deal.business.phone,
          lat: this.currentDealLocation["lat"],
          lng: this.currentDealLocation["lng"]
        }
        window.NativeScript.map(business)
      } else {
        window.open(this.mapLink, "_blank")
      }
    }
  }
}

function show_deal_claim_click(award_method) {
  if (award_method != 'scan') {
    return true
  } else {
    return false
  }
}
function show_deal_claim_qr(award_method) {
  if (award_method != 'click') {
    return true
  } else {
    return false
  }
}
</script>
<style scoped>
.shop {
  background-color: rgb(165,63,151);
}
.todo {
  background-color: rgb(0,140,154);
}
.eat {
  background-color: rgb(215,25,32);
}
.stay {
  background-color: rgb(92,45,145);
}
.services {
  background-color: rgb(192,72,25);
}

.back {
  padding: 1.5rem;
}
.buttons p {
  width: 100%;
}
</style>
